@use '@angular/material' as mat;
@import '../node_modules/@angular/material/theming';

// Styles for the docs app that are based on the current theme.
@mixin avr-app-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .avr-app-background {
    background: mat.get-color-from-palette($background, background);
  }

  .avr-primary-header {
    background: mat.get-color-from-palette($primary);

    h1 {
      color: mat.get-color-from-palette($primary, default-contrast);
    }
  }

  a {
    color: mat.get-color-from-palette($accent);
    text-decoration: none;
    font-weight: 300;
    transition: background-color .5s ease-in-out;
  }

  a.active {
    background-color: mat.get-color-from-palette($foreground, base, 0.04);
  }

  .avr-footer {
    background: mat.get-color-from-palette($primary);
    color: mat.get-color-from-palette($primary, default-contrast);
  }

  kbd {
    color: mat.get-color-from-palette($accent, default-contrast);
    background: mat.get-color-from-palette($accent);
    border-radius: 2px;
  }

  .muted {
    color: mat.get-color-from-palette($foreground, base, 0.62);
  }

  .pointer {
    cursor: pointer;
  }

  .hoverable:hover {
    background-color: mat.get-color-from-palette($foreground, base, 0.04);
  }

  body.avr-app-background {
    max-width: 90em;
    margin: auto
  }

  blockquote {
    border-left: 4px solid rgba(128, 128, 128, 0.3);
    margin: 1.5em 10px;
    padding: 0 8px;
  }

  // fix for angular checkbox not wrapping (https://github.com/angular/components/issues/8416#issuecomment-346380860)
  .mat-checkbox-layout {
    white-space: normal !important;
  }

  .preserve-whitespace {
    white-space: pre-wrap;
  }

  .text-monospace {
    font-family: 'Source Code Pro', monospace;
  }
}
